import classes from './styles.module.scss';

import React from 'react';
// Components
// Hooks

const Banner:React.FC = () => {
  return (
    <div className={classes.root} data-container="banner">
      <div className={`container ${classes.container}`}>
        <div className={classes.wrapper}>
          <div className={classes.companyTypeWrapper}>
            <div className={classes.companyTypeBar}/>
            <div className={classes.companyType}>Адвокатське обєднання</div>
          </div>
          <h1 className={classes.companyTitle}>ВУЙЦІК & ПОХОДЖАЙ</h1>
          <h2 className={classes.moto}>Ваш сімейний адвокат</h2>
          <div className={classes.phoneGroup}>
            <div className={`${classes.phoneBox} ${classes.phone1}`}>+38 063 254 20 90</div>
            <div className={`${classes.phoneBox} ${classes.phone2}`}>+38 066 449 00 32</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner;
