import {FC, Fragment} from 'react';
//
import BannerBlock from './BannerBlock';
import AboutBlock from './AboutBlock';
import StagesBlock from './StagesBlock';
import BenefitsBlock from './BenefitsBlock';
import PricingBlock from './PricingBlock';
import TeamBlock from './TeamBlock';

const HomePage: FC = () => {
    return (
        <Fragment>
            <BannerBlock/>
            <AboutBlock/>
            <TeamBlock/>
            <StagesBlock/>
            <BenefitsBlock/>
            <PricingBlock/>
        </Fragment>
    );
}

export default HomePage;
