import styles from './styles.module.scss';

import {FC} from 'react';

import Photo1 from 'images/photo-olka-p.png'
import Photo2 from 'images/photo-olka-v.png'

const About: FC = () => {
    return (
        <div className={styles.root} data-container="team">
            <div className={`container ${styles.container}`}>
                <div className={`${styles.backgroundDark}`}/>
                <div className={`${styles.backgroundWhite}`}/>
                <div className={`${styles.backgroundAureole}`}/>

                <div className={`sectionTitle ${styles.sectionTitle}`}>КОМАНДА</div>
                <div className={styles.sectionTitleBar}/>

                <div className={`${styles.contentWrapper} ${styles.laptop}`}>
                    <div className={styles.horizontalContent}>
                        <div className={styles.olka1}>
                            <div className={styles.firstName}>Ольга</div>
                            <div className={styles.lastName}>Походжай</div>
                            <div className={styles.jobTitle}>адвокат</div>
                            <div className={styles.btn}>
                                <a href="https://erau.unba.org.ua/profile/67641" target="_blank" rel="noreferrer">Перейти до реєстру</a>
                            </div>
                        </div>
                        <img alt="Ольга Походжай" src={Photo1} />
                        <img alt="Ольга Вуйцік" src={Photo2} />
                        <div className={styles.olka2}>
                            <div className={styles.firstName}>Ольга</div>
                            <div className={styles.lastName}>Вуйцік</div>
                            <div className={styles.jobTitle}>адвокат</div>
                            <div className={styles.btn}>
                                <a href={"https://erau.unba.org.ua/profile/52457"} target="_blank" rel="noreferrer">Перейти до реєстру</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.contentWrapper} ${styles.mobile}`}>
                    <div className={styles.row}>
                        <div className={styles.photoWrapper}>
                            <img alt="Ольга Вуйцік" src={Photo2}/>
                        </div>
                        <div className={styles.about}>
                            <div className={styles.firstName}>Ольга</div>
                            <div className={styles.lastName}>Вуйцік</div>
                            <div className={styles.jobTitle}>адвокат</div>
                            <div className={styles.btn}>
                                <a href={"https://erau.unba.org.ua/profile/52457"} target="_blank" rel="noreferrer">Перейти до реєстру</a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.photoWrapper}>
                            <img alt="Ольга Походжай" src={Photo1}/>
                        </div>
                        <div className={styles.about}>
                            <div className={styles.firstName}>Ольга</div>
                            <div className={styles.lastName}>Походжай</div>
                            <div className={styles.jobTitle}>адвокат</div>
                            <div className={styles.btn}>
                                <a href={"https://erau.unba.org.ua/profile/67641"} target="_blank" rel="noreferrer">Перейти до реєстру</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
