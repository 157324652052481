import { FC, Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Cookies from 'components/Cookies';

import HomePage from 'pages/HomePage';

const App:FC = () => {
  return (
    <Fragment>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />
      {/*<Cookies />*/}
    </Fragment>
  );
}

export default App;
