import classes from './styles.module.scss';

import {FC} from 'react';
import StageIcon1 from 'images/stages-1.svg'
import StageIcon2 from 'images/stages-2.svg'
import StageIcon3 from 'images/stages-3.svg'
import StageIcon4 from 'images/stages-4.svg'
import StageIcon5 from 'images/stages-5.svg'
//import CircleImage from 'images/stages-circle-1.svg'


const Stages: FC = () => {
    return (
        <div className={classes.root} data-container="stages">
            <div className={classes.sectionTitleWrapper}>
                <div className={'sectionTitle'}> Етапи роботи</div>
                <div className={classes.sectionTitleBar}/>
            </div>

            <div className={`container ${classes.container}`}>
                <div className={`${classes.backgroundDark}`}/>
                <div className={classes.content}>
                    <div>
                        <img alt="icon" src={StageIcon1} className={classes.stageIcon}/>
                        <div className={classes.stageTitle}>Аналіз справи та первинна консультація</div>
                        <p className={classes.stageDesc}>Усна юридична-онлайн консультація або усна юридична консультація в
                            нашому офісі у м.Львів</p>
                    </div>
                    <div className={`${classes.box2}`}>
                        <img alt="icon" src={StageIcon2} className={classes.stageIcon}/>
                        <div className={classes.stageTitle}>Збір документів</div>
                        <div className={classes.stageDesc}>Ви можете обрати зручний для вас спосіб:</div>
                        <div className={classes.stageDescList}>
                            <div className={classes.stageDescListItem}>
                                <div className={classes.horizontalBarSmall}/>
                                <div>Надіслати відскановані або якісно сфотографовані нам на електронну
                                    адресу/месенджер;
                                </div>
                            </div>
                            <div className={classes.stageDescListItem}>
                                <div className={classes.horizontalBarSmall}/>
                                <div>Надіслати новою поштою/укрпоштою;</div>
                            </div>
                            <div className={classes.stageDescListItem}>
                                <div className={classes.horizontalBarSmall}/>
                                <div>Принести особисто у офіс</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img alt="icon" src={StageIcon3} className={classes.stageIcon}/>
                        <div className={classes.stageTitle}>Оформлення документів та подання їх до суду</div>
                        <p className={classes.stageDesc}>Підготовка позовної заяви з усіма необхідними додатками, оформлення
                            заяв та клопотань пов’язаних з розглядом справи в суді</p>
                    </div>
                    <div>
                        <img alt="icon" src={StageIcon4} className={classes.stageIcon}/>
                        <div className={classes.stageTitle}>Супровід справи в суді</div>
                        <div className={classes.stageDesc}>
                            Можливі такі варіанти представництва:
                            Адвокат + клієнт
                            Адвокат (без участі клієнта)
                            Клієнт (без участі адвоката)
                        </div>
                    </div>
                    <div>
                        <img alt="icon" src={StageIcon5} className={classes.stageIcon}/>
                        <div className={classes.stageTitle}>Отримання рішення суду</div>
                        <p className={classes.stageDesc}>Рішення суду, яке набрало законної сили, ви можете отримати в суді
                            самостійно або за допомогою адвоката</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stages;
