import styles from './styles.module.scss';

import {FC} from 'react';

import CheckImage from 'images/check.svg'
import InfoImage from 'images/check6.svg'

const Pricing: FC = () => {
    return (
        <div className={styles.root} data-container="pricing">
            <div className={`container ${styles.container}`}>
                <div className={`${styles.backgroundDark}`}/>
                <div className={styles.content}>
                    <div className={styles.sectionTitleWrapper}>
                        <div className={styles.sectionTitleBar}></div>
                        <div className={'sectionTitle'}>Вартість</div>
                    </div>

                    <div className={styles.teaser}>
                        <div>
                            У разі замовлення кількох пакетів послуг діє
                        </div>
                        <div>
                            <i>індивідуальний підхід</i> визначення вартості
                        </div>
                    </div>
                    <div className={styles.priceContainer}>
                        <div>
                            <div className={styles.title}>
                                Консультація
                            </div>
                            <div className={styles.price}>
                                <span className={styles.priceValue}>500</span> <i>грн</i>
                            </div>
                            <div className={styles.servicesContainer}>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Консультація по телефону
                                        месенджерах або в офісі
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Відповідь на всі запитання щодо вашої ситуаціі
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Визначення плану подальших дій
                                    </div>
                                </div>
                            </div>
                            <div className={styles.bottomBar}/>
                        </div>
                        <div>
                            <div className={styles.title}>
                                Розлучення
                            </div>
                            <div className={styles.price}>
                                від <span className={styles.priceValue}>4 000</span> <i>грн</i>
                            </div>
                            <div className={styles.servicesContainer}>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Оформлення позовної заяви з додатками та поданням до суду
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Оформлення заяв та клопотань повя'заних з роглядом справи
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        <span>Юридичний супровід:</span><br/>
                                        <span>- адвокат+клієнт</span><br/>
                                        <span>- адвокат (без участі клієнта)</span><br/>
                                        <span>- клієнт (без участі адвоката)</span>
                                    </div>
                                </div>
                                <div className={styles.info}>
                                    <div><img alt="check icon" src={InfoImage}/></div>
                                    <div>
                                        Оформлення заяв та клопотань повя'заних з роглядом справи
                                    </div>
                                </div>
                                <div className={styles.info}>
                                    <div><img alt="check icon" src={InfoImage}/></div>
                                    <div>
                                        Вартість може змінюватись від обраного вами юридичного супроводу
                                    </div>
                                </div>
                            </div>
                            <div className={styles.bottomBar}/>
                        </div>
                        <div>
                            <div className={styles.title}>
                                Аліменти
                            </div>
                            <div className={styles.price}>
                                <span className={styles.priceValue}>3 000</span> <i>грн</i>
                            </div>
                            <div className={styles.servicesContainer}>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Готуємо та подаємо документи до суду
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Отримання позитивного результату до 30 днів
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Буз участі клієнта та іншої сторони
                                    </div>
                                </div>
                                <div className={styles.info}>
                                    <div><img alt="check icon" src={InfoImage}/></div>
                                    <div>
                                        Судовий збір не сплачується
                                    </div>
                                </div>
                            </div>
                            <div className={styles.bottomBar}/>
                        </div>
                        <div>
                            <div className={styles.title}>
                                Поділ майна
                            </div>
                            <div className={styles.price}>
                                від <span className={styles.priceValue}>10 000</span> <i>грн</i>
                            </div>
                            <div className={styles.servicesContainer}>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Консультація, визначення обсягу майна придбаного під час шлюбу
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Визначення ринкової вартості майна (проведення оцінки із залученням експерта)
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Оформлення позовної заяви з додатками та подання до суду
                                    </div>
                                </div>
                                <div>
                                    <div><img alt="check icon" src={CheckImage}/></div>
                                    <div>
                                        Оформлення та подання до суду процесуальних заяв та клопотань пов’язаних з
                                        розглядом справи
                                    </div>
                                </div>
                            </div>
                            <div className={styles.bottomBar}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing;
