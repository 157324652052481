import classes from './Header.module.scss';
import Logo from 'images/logo.svg'

import {FC, useCallback, useState, useLayoutEffect, useEffect} from 'react';
import {Link} from 'react-router-dom';
// Components
import Icon from 'components/Icon';
// Hooks
import useScrollIntoView from 'hooks/useScrollIntoView';

type WindowWithDataLayer = Window & {
    gtag: Function;
};

declare const window: WindowWithDataLayer;


interface INavItem {
    id: string;
    title: string;
    hidden?: boolean;
}

const navItems: INavItem[] = [
    {id: 'banner', title: '', hidden: true},
    {id: 'about', title: 'Про нас'},
    {id: 'team', title: 'Наша команда'},
    {id: 'stages', title: 'Етапи роботи'},
    {id: 'logo', title: ''},
    {id: 'benefits', title: 'Переваги'},
    {id: 'pricing', title: 'Вартість'},
    {id: 'contacts', title: 'Контакти'},
];

const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.6
};

const HeaderNav: FC = () => {
    const {scrollTo} = useScrollIntoView();

    const [openMenu, setOpenMenu] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);

    const observerCallback = useCallback((entries: any) => {
        entries.forEach((entry: any, index: number) => {
            if (entry.isIntersecting) {
                const id = entry.target.dataset.container;
                if (id) {
                    setActiveMenuItem(id);

                    if (window.gtag) {
                        window.gtag('event', 'section_view', {value: id})
                    }
                }
            }
        });
    }, []);

    useLayoutEffect(() => {
        const blocks = navItems.map((item: INavItem) => document.querySelector(`[data-container="${item.id}"]`));
        const observer = new IntersectionObserver(observerCallback, options);
        blocks.forEach((block: Element | null) => {
            if (block) {
                observer.observe(block);
            }
        });
        return () => {
            observer.disconnect();
        }
        // eslint-disable-next-line
    }, []);

    const toggleMenu = () => setOpenMenu(!openMenu);

    const handleClick = (containerName: string) => {
        scrollTo(containerName);
        toggleMenu();

        if (window.gtag) {
            window.gtag('event', 'section_click', {value: containerName})
        }
    }

    const renderNavItem = (item: INavItem) => {
        if (item.id === 'logo') {
            return (
                <Link key={`nav-link-item-${item.id}`} className={classes.logo} to="/">
                    <img src={Logo} alt="logo"/>
                </Link>
            )
        }

        return (
            <Link
                key={`nav-link-item-${item.id}`}
                className={`
                          ${classes.navItem}
                          ${item.id === activeMenuItem ? classes.navItemActive : ''}
                          ${item.hidden ? 'hidden' : ''}
                        `}
                onClick={() => handleClick(item.id)}
                to={{hash: `#${item.id}`}}
            >{item.title}</Link>
        )
    }

    useEffect(() => {
        const body = document.getElementsByTagName('body');
        body[0].className = openMenu ? 'page' : '';
    }, [openMenu]);

    return (
        <div className={classes.navWrapper}>
            <div className={classes.nav}>
                {navItems.map((item: INavItem) => (
                    renderNavItem(item)
                ))}
            </div>
            <button title='Open menu' className={`button button-icon ${classes.buttonIcon}`} onClick={toggleMenu}>
                <Icon icon={openMenu ? 'Close' : 'MenuFilled'}/>
            </button>
            <div className={`${classes.navCompact} ${openMenu ? classes.navIsOpen : ''}`}>
                {navItems.filter(item => item.id !== 'logo').map((item: INavItem) => (
                    renderNavItem(item)
                ))}
            </div>
        </div>
    )
}

export default HeaderNav;
