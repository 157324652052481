import { FC } from 'react';

import HeaderNav from './HeaderNav';
import classes from './Header.module.scss';
import {Link} from 'react-router-dom';
import Logo from '../../images/logo.svg';

const Header:FC = () => {
  return (
    <header className={classes.root}>
      <div className={`container ${classes.container}`}>
        <div/>
        <Link className={classes.logo} to="/">
            <img src={Logo} alt="logo"/>
        </Link>
        <HeaderNav />
      </div>
    </header>
  );
}

export default Header;
