import classes from './styles.module.scss';

import { FC } from 'react';
import BannerImage from 'images/about_banner1.png'


const About:FC = () => {
  return (
    <div className={classes.root} data-container="about">
      <div className={`container ${classes.container}`}>
        <div className={classes.banner}>
            <img className={classes.desktopImage} alt="Background" src={BannerImage} />
        </div>
        <div className={classes.info}>
          <div className={classes.sectionTitleBar}></div>
          <div className={'sectionTitle'}>Про нас</div>
          <p>
          Ми надаємо якісну та кваліфіковану правову допомогу, яка базується на <span className={classes.highlight}>пятнадцятирічному</span> досвіді роботи у сфері вирішення сімейних та спадкових спорів.
          </p>
          <p>
            Основним нашим принципом роботи з клієнтами є <span className={classes.highlight}>чесність</span>. Ми завчасно повідомляємо наших клієнтів про всі можливі негативні та матеріальні ризики, що дає змогу клієнту прийняти виважене рішення ще до подачі позову до суду та уникнути непередбачуваних наслідків.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About;
