import classes from './styles.module.scss';

import {FC} from 'react';
import BenefitIcon1 from 'images/benefits-icon-1.svg'
import BenefitIcon2 from 'images/benefits-icon-2.svg'
import BenefitIcon3 from 'images/benefits-icon-3.svg'
import BenefitIcon4 from 'images/benefits-icon-4.svg'
import BenefitIcon5 from 'images/benefits-icon-5.svg'

const About: FC = () => {
    return (
        <div className={classes.root} data-container="benefits">
            <div className={`container ${classes.container}`}>
                <div className={classes.sectionTitleWrapper}>
                    <div className={classes.horizontalBarLarge}/>
                    <div className={'sectionTitle'}>ПЕРЕВАГИ</div>
                </div>
                <div className={classes.list}>
                    <div className={classes.itemWrapper}>
                        <img alt="icon" src={BenefitIcon1} className={classes.itemIcon}/>
                        <span>Професіоналізм</span>
                    </div>
                    <div className={classes.itemWrapper}>
                        <img alt="icon" src={BenefitIcon2} className={classes.itemIcon}/>
                        <span>Індивідуальний підхід</span>
                    </div>
                    <div className={classes.itemWrapper}>
                        <img alt="icon" src={BenefitIcon3} className={classes.itemIcon}/>
                        <span>Від 15 років досвіду</span>
                    </div>
                    <div className={classes.itemWrapper}>
                        <img alt="icon" src={BenefitIcon4} className={classes.itemIcon}/>
                        <span>Результативність</span>
                    </div>
                    <div className={classes.itemWrapper}>
                        <img alt="icon" src={BenefitIcon5} className={classes.itemIcon}/>
                        <span>Конфіденційність</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
