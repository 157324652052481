import styles from './Footer.module.scss';
import {FC} from 'react';
import mailIcon from 'images/mail.svg'
import phoneIcon from 'images/phone.svg'
import instagramIcon from 'images/instagram.svg'
import facebookIcon from 'images/facebook.svg'
import locationIcon from 'images/locationmarker.svg'

const Footer: FC = () => {
    return (
        <footer className={styles.root} data-container="contacts">
            <div className={`container ${styles.container}`}>

                <h1 className={styles.title}>АО “Вуйцік і Походжай”</h1>
                <div className={styles.content}>
                    <div>
                        <div>ЄДРПОУ 44032010</div>
                        <div><img src={mailIcon} alt="пошта"/>vplawуer@ukr.net</div>
                        <div><img src={phoneIcon} alt="телефон"/>+38 066 449 00 32</div>
                        <div><img src={phoneIcon} alt="телефон"/>+38 063 254 20 90</div>
                        <div><img src={locationIcon} alt="гео локація"/>м. Львів, вул. Наукова 7а, оф. 204</div>
                    </div>
                </div>

                <div className={styles.separator}>
                </div>

                <div className={styles.social}>
                    <a href="https://www.facebook.com/Vuitsik.and.Pokhodzhai" target="_blank" rel="noreferrer">
                        <img alt="facebook" src={facebookIcon}/>
                    </a>
                    <a href="https://instagram.com/vuitsik_and_pokhodzhai" target="_blank" rel="noreferrer">
                        <img alt="instagram" src={instagramIcon}/>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
